const createUserState = {
  firstName: '',
  lastName: '',
  username: '',
  password: '',
  confirmPassword: '',
  preferences: {
    agreeTermsConditions: false,
    agreePrivacyPolicy: false,
    personalDataProcessing: false,
  },
};

export default createUserState;
