import { TenantInfoTheme } from '../../interface/tenant';
import breakpoints from './breakpoints';
import colors from './colors';
import fontSize from './fontSize';

const defaultTheme = {
  breakpoints,
  colors,
  fontSize,
  logo: {
    dashboard: '/images/default-logo-YEM.svg',
    login: '/images/default-logo-YEM-login.svg',
  },
};

export const overrideTheme = (customTheme: TenantInfoTheme) => ({
  ...defaultTheme,
  logo: {
    dashboard: customTheme.dashboardLogo,
    login: customTheme.loginLogo,
  },
  colors: {
    ...defaultTheme.colors,
    ...customTheme,
  },
});

export default defaultTheme;
