import qs from 'qs';

export const getQueryParameters = () => {
  const decoder = (str) => str;
  const includeSpecialCharacter = window.location.search.includes('+');

  return qs.parse(window.location.search, {
    ignoreQueryPrefix: true,
    decoder: includeSpecialCharacter ? decoder : undefined,
  });
};
