import 'moment/locale/es';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/nl';

import { ConfigProvider } from 'antd';
import enUs from 'antd/lib/locale/en_US';
import esES from 'antd/lib/locale/es_ES';
import frFR from 'antd/lib/locale/fr_FR';
import itIt from 'antd/lib/locale/it_IT';
import nlNl from 'antd/lib/locale/nl_NL';
import i18next from 'i18next';
import moment from 'moment';
import React, { useState } from 'react';

import { getQueryParameters } from '../utils/url';
import { useUserInfo } from './UserInfoContext';

const LocalContext = React.createContext(undefined);

const LocalProvider = ({ children }) => {
  const { lng } = getQueryParameters();
  const { userInfo } = useUserInfo();
  const { language } = userInfo;
  const [localAnt, setLocalAnt] = useState(undefined);
  const [local, setLocal] = useState(lng || language || 'en');

  React.useEffect(() => {
    if (language === 'it') setLocalAnt(itIt);
    else if (language === 'es') setLocalAnt(esES);
    else if (language === 'fr') setLocalAnt(frFR);
    else if (language === 'nl') setLocalAnt(nlNl);
    else setLocalAnt(enUs);

    moment.locale(language);
    i18next.changeLanguage(local || language);
  }, [language, lng, local]);

  if (!localAnt) {
    return null;
  }

  return (
    <LocalContext.Provider value={{ local, setLocal }}>
      <ConfigProvider locale={localAnt}>{children}</ConfigProvider>
    </LocalContext.Provider>
  );
};

function useLocal() {
  const context = React.useContext(LocalContext);
  if (context === undefined) {
    throw new Error('useLocal must be used within a LocalProvider');
  }
  return context;
}

export { LocalContext, LocalProvider, useLocal };
