import { changeAntdTheme } from 'mini-dynamic-antd-theme';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { ThemeProvider } from 'styled-components';

import { useGetTenantInfo } from '../endpoints/tenant/useGetTenantInfo';
import theme, { overrideTheme } from '../style/theme';

const TenantContext = createContext(null);

// For testing in localhost we can define a MA domain name
const domain =
  window.location.hostname === 'localhost' && process.env.REACT_APP_LOCALHOST_FORCED_DOMAIN
    ? process.env.REACT_APP_LOCALHOST_FORCED_DOMAIN
    : window.location.hostname;
const regex = /optimization.energy$/;
const shouldGetTenantInfo = regex.exec(domain);

const TenantProvider = ({ children }) => {
  const [customTheme, setCustomTheme] = useState(theme);
  const [isDomainMA, setIsDomainMA] = useState(false);
  const [isLoginCredentialsNeeded, setIsLoginCredentialsNeeded] = useState(false);
  const [favicon, setFavicon] = useState('');
  const [siteTitle, setSiteTitle] = useState('');
  const [tenantName, setTenantName] = useState<string | undefined>();
  const [environmentVariables, setEnvironmentVariables] = useState(process.env);
  const { mutate: getTenantInfo, isSuccess: getTenantInfoSucceed } = useGetTenantInfo();

  useEffect(() => {
    if (!shouldGetTenantInfo) {
      return;
    }

    getTenantInfo(
      { domain },
      {
        onSuccess(data) {
          setEnvironmentVariables((variables) => ({
            ...variables,
            ...data.env,
          }));
          setIsDomainMA(true);
          setIsLoginCredentialsNeeded(!!data?.loginWithCredentialsEnabled);
          setSiteTitle(data.siteTitle);
          setFavicon(data.favicon);
          setTenantName(data.tenant);
        },
        onSettled(data) {
          if (data) {
            setCustomTheme(() => overrideTheme(data.theme));
            changeAntdTheme(data.theme.baseColor);
          } else {
            changeAntdTheme(theme.colors.baseColor);
          }
        },
      },
    );
  }, [getTenantInfo]);

  if (!getTenantInfoSucceed && shouldGetTenantInfo) {
    return null;
  }

  return (
    <ThemeProvider theme={customTheme}>
      <TenantContext.Provider
        value={{ env: environmentVariables, isDomainMA, isLoginCredentialsNeeded, favicon, siteTitle, tenantName }}
      >
        {children}
      </TenantContext.Provider>
    </ThemeProvider>
  );
};

function useTenant() {
  const context = useContext(TenantContext);
  if (context === undefined) {
    throw new Error('useTenant must be used within a TenantProvider');
  }
  return context;
}

export { TenantContext, TenantProvider, useTenant };
