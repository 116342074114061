const basketState = {
  company: '',
  name: '',
  email: '',
  address: '',
  postalCode: '',
  city: '',
  country: '',
  vatNumber: '',
};

export default basketState;
